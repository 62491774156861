<template>
  <a-modal
    v-model="personVisible"
    :title="(isAdd ? '添加' : '修改') + '账号'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="700"
  >
    <a-form-model ref="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
      <a-form-model-item
        label="人员名称"
        prop="name"
        :rules="[{ required: true, message: '请输入人员名称', trigger: 'blur' }]"
      >
        <a-input v-model.trim="formquery.name" autocomplete="off" placeholder="请输入人员名称" />
      </a-form-model-item>
      <a-form-model-item
        label="登陆邮箱"
        prop="loginId"
        :rules="[
          {
            required: true,
            message: '请填写登陆邮箱',
            trigger: 'blur',
          },
          {
            message: '登陆邮箱必须为邮箱格式',
            trigger: 'blur',
            pattern: /^[A-Za-zd0-9_-]+([-_.][A-Za-zd0-9_-]+)*@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
          },
        ]"
      >
        <a-input v-model.trim="formquery.loginId" :disabled="!isAdd" autocomplete="off" placeholder="请输入登陆邮箱" />
      </a-form-model-item>
      <a-form-model-item
        label="登录密码"
        prop="password"
        v-if="isAdd"
        :rules="[{ required: true, trigger: 'blur', validator: validatePass }]"
      >
        <a-input
          v-model.trim="formquery.password"
          ref="password"
          :type="isShow ? '' : 'password'"
          autocomplete="new-password"
          placeholder="请输入登录密码"
        >
          <a-icon style="cursor: pointer" @click="changeIsShow" slot="suffix" type="eye" />
        </a-input>
      </a-form-model-item>

      <a-form-model-item
        label="角色"
        prop="role"
        :rules="[{ required: true, message: '请选择角色', trigger: 'change' }]"
      >
        <a-select @change="changRole" v-model="formquery.role" allowClear placeholder="请选择角色">
          <a-select-option v-for="item in roleLists" :key="item.id" :isadmin="item.isAdmin">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="应用选择" prop="appType">
        <a-radio-group v-model="formquery.appType">
          <a-radio value="1">全部</a-radio>
          <a-radio value="0">指定应用</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="关联应用" prop="appList" v-if="+formquery.appType === 0">
        <m-select-more
          style="width: 400px"
          v-model="formquery.appList"
          :allData="appLists"
          :searchById="true"
          :hasIcon="true"
          :showLabel="false"
          :showId="true"
        />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="sts" v-if="!isAdd">
        <a-radio-group v-model="formquery.sts">
          <a-radio value="A">开启</a-radio>
          <a-radio value="S">关闭</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="是否显示广告平台" prop="showPlat">
        <a-radio-group v-model="formquery.showPlat">
          <a-radio value="1">开启</a-radio>
          <a-radio value="0">关闭</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { Encrypt } from '@/utils/jsencrypt'
import mixGetList from '@/mixins/getList'
import { addSubAccount, updateSubAccount, getAppListByUser } from '@/api/system'
export default {
  mixins: [mixGetList],
  name: 'PersonModal',
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      formquery: {
        name: undefined,
        loginId: undefined,
        appList: [],
        sts: 'S',
        appType: '1',
        showPlat: undefined,
        role: undefined
      },
      rules: {},
      isShow: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    personInfo: {
      default: () => ({}),
      type: Object
    },
    roleList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    personInfo: {
      handler (val) {
        if (!this.isAdd) {
          this.formquery = JSON.parse(JSON.stringify(val))
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    personVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    roleLists () {
      const arr = this.roleList.filter((item) => item.isAdmin !== '1')
      return arr
    }
  },
  created () {
    !this.isAdd && this.changAppList()
    this.isAdd && (this.formquery.showPlat = '1')
  },
  methods: {
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 修改 获取应用
    async changAppList (role, option) {
      const { id = '', isAdmin = '' } = this.personInfo
      const resp = await getAppListByUser({ id, isAdmin })
      this.appLists = resp.data || []
      const arr = resp.data.filter((item) => item.isBinding).map((item) => item.id)
      this.formquery.appList = arr
    },
    // 根据角色选择应用
    changRole (role, option) {
      // 账户类型 0:其他 1 管理员 2 媒体
      const type = option.data.attrs.isadmin
      if (!this.isAdd) return
      this.getAppLists({ isAdmin: type })
      this.formquery.appList = []
    },
    changeIsShow () {
      this.isShow = !this.isShow
      this.$refs.password.focus()
    },
    handleCancel () {
      this.personVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.isAdd) {
            this.formquery.password = Encrypt(this.formquery.password)
            const resp = await addSubAccount(this.formquery)
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changePerson')
              this.personVisible = false
            }
          } else {
            const resp = await updateSubAccount(this.formquery)
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changePerson')
              this.personVisible = false
            }
          }
        } else {
          return false
        }
      })
    },
    validatePass (rule, value, callback) {
      if (value === '') {
        callback(new Error('新密码不能为空！请重新输入'))
      } else {
        if (value.length < 8) {
          callback(new Error('密码不低于8位'))
        } else {
          callback()
        }
      }
    }
  }
}
</script>

<style>
</style>
